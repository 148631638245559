<template>
    <div id="summary-grid">
        <div
            v-if="isFileSelectionEmpty"
            class="flex flex-row text-gray-600"
        >
            <div class="text-sm text-center w-full">
                No files selected for upload.
            </div>
            <div class="text-sm text-center w-full">
                Please return to the first step.
            </div>
        </div>
        <AgGridVue
            v-if="!isFileSelectionEmpty"
            class="ag-theme-alpine w-full block"
            style="min-height: 250px"
            :domLayout="grid.state.domLayout.value"
            :defaultColDef="grid.state.defaultColDef.value"
            :columnDefs="grid.state.columnDefs.value"
            :rowSelection="grid.state.rowSelection.value"
            :rowHeight="grid.state.rowHeight.value"
            :rowData="grid.state.rowData.value"
            @grid-ready="grid.actions.onGridReady"
            @column-resized="grid.actions.onColumnResized"
            @selection-changed="grid.actions.onSelectionChanged"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent, computed, toRefs } from 'vue';
    import { useStore } from 'vuex';

    // <!-- COMPONENTS -->
    import { AgGridVue } from 'ag-grid-vue3';

    // <!-- COMPOSABLES -->
    import { useUploadSummaryGrid } from '~CSVUploader/hooks/grid/useUploadSummaryGrid';

    // <!-- TYPES -->

    /** @typedef {import('@/store/types/uploader/state/UploadRecord').UploadRecord} UploadRecord */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'UploadSummaryGrid',
        components: {
            AgGridVue,
        },
        props: {
            multiple: Boolean,
            prompt: {
                type: String,
                default: 'Summary.',
            },
            records: {
                /** @type {V.PropType<Map<String, UploadRecord>>} */
                type: Object,
                required: true,
            },
        },
        emits: ['change:rowData', 'resize:grid', 'assign:profile', 'alert'],
        setup(props, context) {
            // ==== PROPS ====
            const { records } = toRefs(props);

            // ==== COMPUTED PROPERTIES ====
            /**
             * Is the file selection set empty?
             */
            const isFileSelectionEmpty = computed(() => {
                return records.value.size <= 0;
            });

            // ===== GRID =====
            /**
             * Grid props.
             */
            const summaryGridProps = {
                records,
            };

            // Get the file grid module.
            const { ...grid } = useUploadSummaryGrid(
                context,
                useStore(),
                summaryGridProps
            );

            // Configure the grid module.
            grid.useDefaultColDef({
                flex: 1,
                minWidth: 50,
                width: 100,
                resizable: true,
                sortable: true,
                filter: false,
            });
            // Update the column definitions.
            grid.actions.updateColumnDefs();
            return {
                grid,
                isFileSelectionEmpty,
            };
        },
    });
</script>
